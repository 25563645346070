import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';
import { Tooltip, Toast, Popover } from 'bootstrap';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	console.log('document ready');

	// Navigation levels
	/*
	$('.navbar .dropdown').hover(function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);
	}, function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);
	});*/

	$('.navbar .dropdown > a').click(function(){
		location.href = this.href;
	});

	// Search - Hide label on focus

	$('.form-wrap .wp-block-search__input').focus(function(){
        $('label.wp-block-search__label').hide();
	});

	var field = 's';
	var url = window.location.href;
	if(url.indexOf('?' + field + '=') != -1){
		$('label.wp-block-search__label').hide();
	}else if(url.indexOf('&' + field + '=') != -1){
		$('label.wp-block-search__label').hide();
	}


	// Dropdown columns width

	$('.dropdown-menu .dropdown-inner').each(function() {

		var dropdownColumns = $(this).children('.dropdown-column').length;

		if(dropdownColumns == 1){ 
			$(this).children('.dropdown-column').addClass('dropdown-column--one');
		}else if(dropdownColumns == 2){
			$(this).children('.dropdown-column').addClass('dropdown-column--two');
		}else if(dropdownColumns == 3){
			$(this).children('.dropdown-column').addClass('dropdown-column--three');
		}else if(dropdownColumns == 4){
			$(this).children('.dropdown-column').addClass('dropdown-column--four');
		}else if(dropdownColumns == 5){
			$(this).children('.dropdown-column').addClass('dropdown-column--five');
		}else if(dropdownColumns == 6){
			$(this).children('.dropdown-column').addClass('dropdown-column--six');
		}

	});

	// Dropdown animation
	$('.dropdown-toggle').each(function() {

		$(this).mouseover(function() {
			$(this).siblings('.dropdown-menu').addClass('dropdown-active');
		});

		$(this).mouseleave(function() {
			$(this).removeClass('dropdown-active');
		});

	});

	// Add ID to Toolset form field

	jQuery(document).ready(function ($){
    	$('input[name="wpv_post_search"]').attr('id', 'wpv-post-search');
	})

	// Delete ::after if a has img

	$('.article-content .article-body a').each(function() {
		if ( $(this).children('img').length > 0 ) {
			$(this).addClass('hide-after');
		}
	});


	// Nieuw op REVA stijlen

	$('.page-template-template-nieuwopreva .grid-item .btn').removeClass('btn--white').addClass('btn--purple');

	// Tooltips



	$('.tooltip-wrap').each(function(){

		$(this).children('.trigger').click(function(){
			$('.tooltip').removeClass('active');
			$(this).siblings('.tooltip').addClass('active');
		});

		$(function() {
		    var ignore= Array('.trigger');
		    ignore.forEach(function (item) {
		      $(item).click(function(){ return false; });
		    });
		    $(document).on("click", function() {
		      $(".tooltip").removeClass('active'); });
		});

	});

	// Change Complianz close button aria label + delete aria-live

	$(".cmplz-close").attr("id","cmplz-close");
	document.getElementById('cmplz-close').setAttribute('aria-label', 'cookiebanner sluiten');

	$(".cmplz-cookiebanner").attr("id","cmplz-cookiebanner");
	document.getElementById('cmplz-cookiebanner').removeAttribute("aria-live");


	// Change Gravity Forms form title to h2


	$('.gform_heading').find('h3.gform_title').replaceWith(function() {
	        return '<h2 class="gform_title" style="margin-top: 0;">' + $(this).text() + '</h2>';
	});





});